import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import Select from 'react-select';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useDemoData } from "@mui/x-data-grid-generator";
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import { CiSearch } from 'react-icons/ci';
import DateRangePicker from '../components/datepicker';
import { BiSolidCheckCircle } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import Dropdown from 'react-bootstrap/Dropdown';
import Innerfooter from '../common/innerfooter';


const options = [
  { value: 'us', label: 'US' },
  { value: 'korea', label: 'korea' },
  { value: 'japan', label: 'Japan' },
  { value: 'india', label: 'India' },
];
const options1 = [
  { value: 'ptnr', label: 'PTNR' },
  { value: 'dsbt', label: 'DSBT' },
  { value: 'DLER', label: 'DLER' },
];
const options2 = [
  { value: 'vvip', label: 'VVIP' },
  { value: 'vip', label: 'VIP' },
  { value: 'normal', label: 'Normal' },
];
const options3 = [
  { value: 'normalapi', label: 'Normal API' },
  { value: 'stopapi', label: 'Stop API' },
];

function Recommended() {

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);



  
  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>
              <div className={`${cascading.innercontent} ${cascading.memberpage}`}>
                <div className={`${cascading.topheadsec}`}>
                  <p className={`${cascading.pagehead}`}>Recommended Composition</p>
                  <p className={`${cascading.pagedesc}`}>trade , sell and hold 10 + cryptocurrencies on
                    Crypto</p>

                </div>
                <div className={`${cascading.topfilterrow} mt-4`}>
                  <div className={`${cascading.selectfiltersec} `}>
                    <div className={`${cascading.reactselect}`}>
                      <Select
                        className='reactselect_container'
                        classNamePrefix="reactselect"
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                        placeholder="US"
                      />
                    </div>
                    <div className={`${cascading.reactselect}`}>
                      <Select
                        className='reactselect_container'
                        classNamePrefix="reactselect"
                        defaultValue={selectedOption1}
                        onChange={setSelectedOption1}
                        options={options1}
                        placeholder="PTNR"
                      />
                    </div>
                    <div className={`${cascading.reactselect}`}>
                      <Select
                        className='reactselect_container'
                        classNamePrefix="reactselect"
                        defaultValue={selectedOption2}
                        onChange={setSelectedOption2}
                        options={options2}
                        placeholder="VVIP"
                      />
                    </div>
                    <div className={`${cascading.reactselect}`}>
                      <Select
                        className='reactselect_container'
                        classNamePrefix="reactselect"
                        defaultValue={selectedOption3}
                        onChange={setSelectedOption3}
                        options={options3}
                        placeholder="Normal API"
                      />
                    </div>
                    <button className={`${cascading.searchbtn} btn`}>Search</button>


                  </div>
                

                </div>
            
                <div>

                </div>
              </div>


            </div>
            <Innerfooter/>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Recommended