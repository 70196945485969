import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Innerfooter from '../common/innerfooter';
import dummypf from '../assests/images/dummypf.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaEllipsisH } from "react-icons/fa";
import Enquirymodal from '../modals/Enquirymodal';
function Enquiry() {
  const [enquirymodal,setEnquirymodal] = useState(false)
  const [userqn, setUserqn] = useState([
    {
      pfimg: dummypf,
      name: "Peter",
      date: "29,Apr 2024",
      que: "How to go community pages ?"

    },
    {
      pfimg: dummypf,
      name: "Peter",
      date: "29,Apr 2024",
      que: "How to go community pages ?"

    }
  ])
 
  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>
              <div className={`${cascading.innercontent} ${cascading.enquirypages}`}>
                <div className={`${cascading.topheadsec}`}>
                  <p className={`${cascading.pagehead}`}>Enquiry</p>

                </div>
                <div className={`${cascading.notedesc}`}>
                  <p>In the first partner settings, is it possible to code the step at which the
                    referral bonus is applied to each partner? Configure in a tree-shaped
                    structure M-SCORE admin settings.</p>
                  <p>In the first partner settings, is it possible to code the step at which the
                    referral bonus is applied to each partner? Configure in a tree-shaped
                    structure M-SCORE admin settings.</p>
                  <p>In the first partner settings, is it possible to code the step at which the
                    referral bonus is applied to each partner? Configure in a tree-shaped
                    structure M-SCORE admin settings.</p>

                  <p>In the first partner settings, is it possible to code the step at which the
                    referral bonus is applied to each partner? Configure in a tree-shaped
                    structure M-SCORE admin settings.</p>

                </div>
                <div className={`${cascading.usersec}`}>
                  {userqn.map((e) => (
                    <div className={`${cascading.usercard}`}>
                      <div className={`${cascading.toprow}`}>
                        <div className={`${cascading.leftpfsec}`}>
                          <div>
                            <img src={e.pfimg} className={`img-fluid ${cascading.pfimg}`} alt='img' />
                          </div>
                          <div>
                            <p className={`${cascading.name}`}>{e.name}</p>
                            <p className={`${cascading.date}`}>{e.date}</p>
                          </div>
                        </div>
                        <div className={`${cascading.statusdrop}`}>
                          <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <FaEllipsisH />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className={`${cascading.dropmenu}`}>
                              <Dropdown.Item href="#/action-1" onClick={()=>setEnquirymodal(true)}>Reply</Dropdown.Item>
                              <Dropdown.Item href="#/action-2">Edit</Dropdown.Item>
                              <Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          

                        </div>
                      </div>
                      <div className={`${cascading.qnsec}`}>
                        <p className={`${cascading.ques}`}>{e.que}</p>
                        <p className={`${cascading.reply}`}>1 Reply</p>

                        </div>
                    </div>
                  ))}


                </div>



              </div>


            </div>
            <Innerfooter />
          </Grid>
        </Grid>
      </div>
      {enquirymodal && <Enquirymodal show={enquirymodal} onClose={()=>setEnquirymodal(false)}/>}
    </>
  )
}

export default Enquiry
