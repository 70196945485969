import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import Select from 'react-select';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useDemoData } from "@mui/x-data-grid-generator";
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import { CiSearch } from 'react-icons/ci';
import DateRangePicker from '../components/datepicker';
import { BiSolidCheckCircle } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import Dropdown from 'react-bootstrap/Dropdown';
import Innerfooter from '../common/innerfooter';
import PartnerRegister from '../modals/partnerregister';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import StyledDataGrid from '../common/StyleDataGrid';
const options = [
  { value: 'us', label: 'US' },
  { value: 'korea', label: 'korea' },
  { value: 'japan', label: 'Japan' },
  { value: 'india', label: 'India' },
];
const options1 = [
  { value: 'ptnr', label: 'PTNR' },
  { value: 'dsbt', label: 'DSBT' },
  { value: 'DLER', label: 'DLER' },
];
const options2 = [
  { value: 'vvip', label: 'VVIP' },
  { value: 'vip', label: 'VIP' },
  { value: 'normal', label: 'Normal' },
];
const options3 = [
  { value: 'normalapi', label: 'Normal API' },
  { value: 'stopapi', label: 'Stop API' },
];
const status = [
  { value: 'normal', label: 'Normal' },
  { value: 'stop', label: 'Stop' },
  { value: 'out', label: 'Out' },
  { value: 'noaccess', label: 'No access' },
];
function Member() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedStatus, setSelectedstatus] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [regmodal, setRegmodal] = useState(false)
  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };
  const columns = [
    { field: 'sno', headerName: '#', minWidth: 60, flex: 1 },
    { field: 'uid', headerName: 'ID', type: 'Date', minWidth: 150, flex: 1 },
    { field: 'name', headerName: 'Name', minWidth: 100, flex: 1 },
    { field: 'email', headerName: 'E-mail', minWidth: 150, flex: 1 },
    { field: 'partner', headerName: 'Partner', minWidth: 150, flex: 1 },
    { field: 'dealer', headerName: 'Dealer', minWidth: 150, flex: 1 },
    { field: 'exjoin', headerName: 'Ex Join', minWidth: 150, flex: 1, renderCell: (params) => <div className={`${cascading.labels}`}><BiSolidCheckCircle fill="#57C644" fontSize={28} /> </div> },
    { field: 'mjoin', headerName: 'M Join', minWidth: 100, flex: 1, renderCell: (params) => <div className={`${cascading.labels}`}><MdCancel fill="#FC3D3D" fontSize={28} /> </div> },
    { field: 'level', headerName: 'Level', minWidth: 150, flex: 1 },
    { field: 'trdday', headerName: 'TRD Day', minWidth: 150, flex: 1 },
    { field: 'firstdeposit', headerName: 'First deposit USDT', minWidth: 150, flex: 1 },
    { field: 'extotal', headerName: 'Ex Total deposit', minWidth: 150, flex: 1 },
    { field: 'totalpnl', headerName: 'Total PnL', minWidth: 150, flex: 1 },
    { field: 'inusdt', headerName: 'Increase USDT', minWidth: 150, flex: 1 },
    { field: 'totalsvr', headerName: 'Total SVR Deposit', minWidth: 150, flex: 1 },
    { field: 'trdstatus', headerName: 'TRD Power Status', minWidth: 150, flex: 1 },
    { field: 'holdusdt', headerName: 'Hold USDT', minWidth: 150, flex: 1 },
    { field: 'mdeposit', headerName: 'M-wallet depoist', minWidth: 150, flex: 1 },
    { field: 'advpayment', headerName: 'Advance payment', minWidth: 150, flex: 1, renderCell: (params) => <div className={`${cascading.labels}`}><MdCancel fill="#FC3D3D" fontSize={28} /> </div> },
    { field: 'date', headerName: 'Date of subcription', minWidth: 150, flex: 1 },
    { field: 'tokenaddress', headerName: 'Token address', minWidth: 150, flex: 1 },

    {
      field: 'status', headerName: 'status', minWidth: 150, flex: 1, renderCell: (params) =>
        <div className='mui_tableselect'>
          <FormControl fullWidth>

            <MuiSelect
              labelId="demo-simple-select-label"
              id="demo-simple-select"
                // value={age}
                // onChange={handleChange}
                defaultValue={'normal'}
            >
              <MenuItem value={"normal"}>Normal</MenuItem>
              <MenuItem value={"stop"}>Stop</MenuItem>
              <MenuItem value={"out"}>out</MenuItem>
              <MenuItem value={"noaccess"}>No Access</MenuItem>
            </MuiSelect>
          </FormControl>
        </div>
    },





  ];

  const rows = [
    { id: 0, sno: 1000, uid: '#123', name: 'SADSADsdfsdf', email: 'asdfsdf@gmail.com', partner: 'Asdasda(1,000)', dealer: 'Asdfasdf(1000)', exjoin: '-100,000', mjoin: 'ON', level: "VVIP", trdday: "1,000 Day", firstdeposit: "9,000,000", extotal: "9,000,000", totalpnl: "9,000,000", inusdt: "9,000,000", totalsvr: "9,000,000", trdstatus: "9,000,000", holdusdt: "9,000,000", mdeposit: "9,000,000", advpayment: "", date: "23.12.31 23:59:59", tokenaddress: "0xsdfww12313", status: "" },
    { id: 1, sno: 1000, uid: '#123', name: 'SADSADsdfsdf', email: 'asdfsdf@gmail.com', partner: 'Asdasda(1,000)', dealer: 'Asdfasdf(1000)', exjoin: '-100,000', mjoin: 'ON', level: "VVIP", trdday: "1,000 Day", firstdeposit: "9,000,000", extotal: "9,000,000", totalpnl: "9,000,000", inusdt: "9,000,000", totalsvr: "9,000,000", trdstatus: "9,000,000", holdusdt: "9,000,000", mdeposit: "9,000,000", advpayment: "", date: "23.12.31 23:59:59", tokenaddress: "0xsdfww12313", status: "" },



  ];
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 1000,
    maxColumns: 4
  });
  const inputElement = useRef();


  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (

      <Pagination
        color="standard"
        shape="rounded"
        page={page + 1}
        // className={`${styles.pagecustom}`}
        count={pageCount}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />

    );
  }
  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>
              <div className={`${cascading.innercontent} ${cascading.memberpage}`}>
                <div className={`${cascading.topheadsec}`}>
                  <p className={`${cascading.pagehead}`}>Member</p>
                  <p className={`${cascading.pagedesc}`}>trade , sell and hold 10 + cryptocurrencies on
                    Crypto</p>

                </div>
                <div className={`${cascading.topfilterrow} mt-4`}>
                  <div className={`${cascading.selectfiltersec} `}>
                    <div className={`${cascading.reactselect}`}>
                      <Select
                        className='reactselect_container'
                        classNamePrefix="reactselect"
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                        placeholder="US"
                      />
                    </div>
                    <div className={`${cascading.reactselect}`}>
                      <Select
                        className='reactselect_container'
                        classNamePrefix="reactselect"
                        defaultValue={selectedOption1}
                        onChange={setSelectedOption1}
                        options={options1}
                        placeholder="PTNR"
                      />
                    </div>
                    <div className={`${cascading.reactselect}`}>
                      <Select
                        className='reactselect_container'
                        classNamePrefix="reactselect"
                        defaultValue={selectedOption2}
                        onChange={setSelectedOption2}
                        options={options2}
                        placeholder="VVIP"
                      />
                    </div>
                    <div className={`${cascading.reactselect}`}>
                      <Select
                        className='reactselect_container'
                        classNamePrefix="reactselect"
                        defaultValue={selectedOption3}
                        onChange={setSelectedOption3}
                        options={options3}
                        placeholder="Normal API"
                      />
                    </div>
                    <button className={`${cascading.searchbtn} btn`}>Search</button>


                  </div>
                  <button className={`${cascading.themebtn} btn`} onClick={() => setRegmodal(true)}>Partner Registration</button>

                </div>
                <div className={`${cascading.tablesec} mt-4`}>
                  <div className={`${cascading.filtersec}`}>
                    <div className={`${cascading.inputinside}`}>
                      <input
                        type="text"
                        placeholder="Search..."
                      // value={searchTerm}
                      // onChange={handleSearchChange}
                      />
                      <CiSearch />
                    </div>
                    <DateRangePicker startDate={startDate} endDate={endDate} onChange={handleDateChange} />


                  </div>
                  <div id="styletable">
                    <DataGrid
                      ref={inputElement}
                      {...data}
                      disableColumnMenu
                      rows={rows}
                      // rowHeight={100}
                      sx={{
                        ...StyledDataGrid(),
                      }}
                      columns={columns}

                      components={{
                        Pagination: CustomPagination,
                      }}
                    // slots={{
                    //   footer: CustomPagination,
                    // }}



                    />
                  </div>

                </div>
                <div>

                </div>
              </div>


            </div>
            <Innerfooter />
          </Grid>
        </Grid>
      </div>
      {regmodal && <PartnerRegister show={regmodal} onClose={() => setRegmodal(false)} />}
    </>
  )
}

export default Member