import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux';

import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Countdown from 'react-countdown';
import { GrStatusInfo } from "react-icons/gr";
import Innerfooter from '../common/innerfooter';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import DateRangePicker from '../components/datepicker';
import progress from '../assests/images/timeprogress.svg';
import timerline from '../assests/images/linetimer.svg';

function Memberdashboard() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const theme = useSelector((state) => state.admin.themeStatus)
  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };
  const [filterdate, setFilterdate] = useState([{
    label: "3D",
  },
  {
    label: "7D",
  },
  {
    label: "1M",
  },
  {
    label: "2M",
  },
  {
    label: "3M",
  },
  {
    label: "4M",
  },
  {
    label: "6M",
  },
  {
    label: "8M",
  },
  {
    label: "10M",
  },
  {
    label: "1Y",
  },
  {
    label: "1Y2M",
  },
  {
    label: "1Y4M",
  },
  {
    label: "1Y6M",
  },
  {
    label: "1Y8M",
  },
  {
    label: "1Y10M",
  },
  {
    label: "2Y",
  },
  {
    label: "2Y3M",
  },
  {
    label: "2Y6M",
  },

  {
    label: "2Y9M",
  },
  {
    label: "3Y",
  },


  ])
  const [chartData, setChartData] = useState({
    series: [{
      data: [
        [1327359600000, 30.95],
        [1327446000000, 31.34],
        [1327532400000, 31.18],
        [1327618800000, 31.05],
        [1327878000000, 31.00],
        [1327964400000, 30.95],
        [1328050800000, 31.24],
        [1328137200000, 31.29],
        [1328223600000, 31.85],
        [1328482800000, 31.86],

        [1332111600000, 34.51],
        [1332198000000, 33.16],
        [1332284400000, 33.56],
        [1332370800000, 33.71],
        [1332457200000, 33.81],
        [1332712800000, 34.40],
        [1332799200000, 34.63],
        [1332885600000, 34.46],
        [1332972000000, 34.48],
        [1333058400000, 34.31],
        [1333317600000, 34.70],
        [1333404000000, 34.31],
        [1333490400000, 33.46],
        [1333576800000, 33.59],
        [1333922400000, 33.22],
        [1334008800000, 32.61],
        [1334095200000, 33.01],
        [1334181600000, 33.55],
        [1334268000000, 33.18],
        [1334527200000, 32.84],
        [1334613600000, 33.84],
        [1334700000000, 33.39],
        [1334786400000, 32.91],
        [1334872800000, 33.06],
        [1335132000000, 32.62],
        [1335218400000, 32.40],
        [1335304800000, 33.13],
        [1335391200000, 33.26],
        [1335477600000, 33.58],
        [1335736800000, 33.55],
        [1335823200000, 33.77],
        [1335909600000, 33.76],
        [1335996000000, 33.32],
        [1336082400000, 32.61],
        [1336341600000, 32.52],
        [1336428000000, 32.67],
        [1336514400000, 32.52],
        [1336600800000, 31.92],
        [1336687200000, 32.20],
        [1336946400000, 32.23],
        [1337032800000, 32.33],
        [1337119200000, 32.36],
        [1337205600000, 32.01],
        [1337292000000, 31.31],
        [1337551200000, 32.01],
        [1337637600000, 32.01],
        [1337724000000, 32.18],
        [1337810400000, 31.54],
        [1337896800000, 31.60],
        [1338242400000, 32.05],
        [1338328800000, 31.29],
        [1338415200000, 31.05],
        [1338501600000, 29.82],
        [1338760800000, 30.31],
        [1338847200000, 30.70],
        [1338933600000, 31.69],
        [1339020000000, 31.32],
        [1339106400000, 31.65],
        [1339365600000, 31.13],
        [1339452000000, 31.77],
        [1339538400000, 31.79],
        [1339624800000, 31.67],
        [1339711200000, 32.39],
        [1339970400000, 32.63],
        [1340056800000, 32.89],
        [1340143200000, 31.99],
        [1340229600000, 31.23],
        [1340316000000, 31.57],
        [1340575200000, 30.84],
        [1340661600000, 31.07],
        [1340748000000, 31.41],
        [1340834400000, 31.17],
        [1340920800000, 32.37],
        [1341180000000, 32.19],
        [1341266400000, 32.51],
        [1341439200000, 32.53],
        [1341525600000, 31.37],
        [1341784800000, 30.43],
        [1341871200000, 30.44],
        [1341957600000, 30.20],
        [1342044000000, 30.14],
        [1342130400000, 30.65],
        [1342389600000, 30.40],
        [1342476000000, 30.65],
        [1342562400000, 31.43],
        [1342648800000, 31.89],
        [1342735200000, 31.38],
        [1342994400000, 30.64],
        [1343080800000, 30.02],
        [1343167200000, 30.33],
        [1343253600000, 30.95],
        [1343340000000, 31.89],
        [1343599200000, 31.01],
        [1343685600000, 30.88],
        [1343772000000, 30.69],
        [1343858400000, 30.58],
        [1343944800000, 32.02],
        [1344204000000, 32.14],
        [1344290400000, 32.37],
        [1344376800000, 32.51],
        [1344463200000, 32.65],
        [1344549600000, 32.64],
        [1344808800000, 32.27],
        [1344895200000, 32.10],
        [1344981600000, 32.91],
        [1345068000000, 33.65],
        [1345154400000, 33.80],
        [1345413600000, 33.92],
        [1345500000000, 33.75],
        [1345586400000, 33.84],
        [1345672800000, 33.50],
        [1345759200000, 32.26],
        [1346018400000, 32.32],
        [1346104800000, 32.06],
        [1346191200000, 31.96],
        [1346277600000, 31.46],
        [1346364000000, 31.27],
        [1346709600000, 31.43],
        [1346796000000, 32.26],
        [1346882400000, 32.79],
        [1346968800000, 32.46],
        [1347228000000, 32.13],
        [1347314400000, 32.43],
        [1347400800000, 32.42],
        [1347487200000, 32.81],
        [1347573600000, 33.34],
        [1347832800000, 33.41],
        [1347919200000, 32.57],
        [1348005600000, 33.12],
        [1348092000000, 34.53],
        [1348178400000, 33.83],
        [1348437600000, 33.41],
        [1348524000000, 32.90],
        [1348610400000, 32.53],
        [1348696800000, 32.80],
        [1348783200000, 32.44],
        [1349042400000, 32.62],
        [1349128800000, 32.57],
        [1349215200000, 32.60],
        [1349301600000, 32.68],
        [1349388000000, 32.47],
        [1349647200000, 32.23],
        [1349733600000, 31.68],
        [1349820000000, 31.51],
        [1349906400000, 31.78],
        [1349992800000, 31.94],
        [1350252000000, 32.33],
        [1350338400000, 33.24],
        [1350424800000, 33.44],
        [1350511200000, 33.48],
        [1350597600000, 33.24],
        [1350856800000, 33.49],
        [1350943200000, 33.31],
        [1351029600000, 33.36],
        [1351116000000, 33.40],
        [1351202400000, 34.01],
        [1351638000000, 34.02],
        [1351724400000, 34.36],
        [1351810800000, 34.39],
        [1352070000000, 34.24],
        [1352156400000, 34.39],
        [1352242800000, 33.47],
        [1352329200000, 32.98],
        [1352415600000, 32.90],
        [1352674800000, 32.70],
        [1352761200000, 32.54],
        [1352847600000, 32.23],
        [1352934000000, 32.64],
        [1353020400000, 32.65],
        [1353279600000, 32.92],
        [1353366000000, 32.64],
        [1353452400000, 32.84],
        [1353625200000, 33.40],
        [1353884400000, 33.30],
        [1353970800000, 33.18],
        [1354057200000, 33.88],
        [1354143600000, 34.09],
        [1354230000000, 34.61],
        [1354489200000, 34.70],
        [1354575600000, 35.30],
        [1354662000000, 35.40],
        [1354748400000, 35.14],
        [1354834800000, 35.48],
        [1355094000000, 35.75],
        [1355180400000, 35.54],
        [1355266800000, 35.96],
        [1355353200000, 35.53],
        [1355439600000, 37.56],
        [1355698800000, 37.42],
        [1355785200000, 37.49],
        [1355871600000, 38.09],
        [1355958000000, 37.87],
        [1356044400000, 37.71],
        [1356303600000, 37.53],
        [1356476400000, 37.55],
        [1356562800000, 37.30],
        [1356649200000, 36.90],
        [1356908400000, 37.68],
        [1357081200000, 38.34],
        [1357167600000, 37.75],
        [1357254000000, 38.13],
        [1357513200000, 37.94],
        [1357599600000, 38.14],
        [1357686000000, 38.66],
        [1357772400000, 38.62],
        [1357858800000, 38.09],
        [1358118000000, 38.16],
        [1358204400000, 38.15],
        [1358290800000, 37.88],


      ]
    }],
    options: {
      colors: ['#e9b54d'],
      chart: {
        id: 'area-datetime',
        type: 'area',
        height: 450,
        zoom: {
          autoScaleYaxis: true
        }
      },
      annotations: {
        yaxis: [
          {
            y: 30,
            borderColor: '#999',
            label: {
              show: true,
              text: 'Support',
              style: {
                color: "#fff",
                background: '#00E396'
              }
            }
          },],
        xaxis: [{
          x: new Date('14 Nov 2012').getTime(),
          borderColor: '#999',
          yAxisIndex: 0,
          label: {
            show: true,
            text: 'Rally',
            style: {
              color: "#fff",
              background: '#775DD0'
            }
          }
        }]
      },
      dataLabels: {
        enabled: false,

      },
      markers: {
        size: 0,
        style: 'hollow',
      },
      xaxis: {
        type: 'datetime',
        min: new Date('01 Mar 2012').getTime(),
        tickAmount: 6,
        labels: {
          style: {
            colors: '#7D6B48'
          }

        }

      },
      yaxis: {
        opposite: true,
        labels: {
          align: 'right',
          style: {
            colors: '#7D6B48'
          }
        },

      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        }
      },
      stroke: {
        colors: ' #63741F'
      },
      fill: {
        type: 'gradient',
        // colors: ['#e9b54d', '#876025', '#2f2110'],
        gradient: {
          type: 'vertical',
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          colorStops: [
            {
              offset: 0,
              color: "#e9b54d",
              opacity: 1
            },
            {
              offset: 80,
              color:theme =="light" ?"#e9b54d" : "#876025",
              opacity: 0.7
            },
            {
              offset: 100,
              color: theme =="light" ?"#e9b54d" : "#2f2110",
              opacity: 0.5
            },

          ]
        }
      },
    },
    selection: '1Y',
  });
  const [chartData1, setChartData1] = useState({
    series: [{
      name: 'Profit',
      data: [31, 40, 28, 51, 42, 109, 100],


    }, {
      name: 'Loss',
      data: [11, 32, 45, 32, 34, 52, 41]
    }],
    options: {
      colors: ['#48B16A', '#B14848'],
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"],
        labels: {
          style: {
            colors: '#7D6B48'
          }
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      yaxis: {
        opposite: true,
        labels: {
          align: 'right',
          style: {
            colors: '#7D6B48'
          }
        },

      },
    },

  });
  const [chartData2, setChartData2] = useState({
    series: [{
      name: 'Assets Trends',
      data: [31, 40, 28, 51, 42, 109, 100],


    },],
    options: {
      colors: ['#e9b54d'],
    
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"],
        labels: {
          style: {
            colors: '#7D6B48'
          }
        },
      },
      stroke: {
        colors: ' #63741F'
      },
      fill: {
        type: 'gradient',
        // colors: ['#e9b54d', '#876025', '#2f2110'],
        gradient: {
          type: 'vertical',
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          colorStops: [
            {
              offset: 0,
              color: "#e9b54d",
              opacity: 1
            },
            {
              offset: 80,
              color: theme =="light" ?"#e9b54d" : "#876025",
              opacity: 0.7
            },
            {
              offset: 100,
              color:  theme =="light" ?"#e9b54d" : "#2f2110",
              opacity: 0.5
            },

          ]
        }
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      yaxis: {
        opposite: true,
        labels: {
          align: 'right',
          style: {
            colors: '#7D6B48'
          }
        },

      },
    },

  });

  const updateData = (timeline) => {
    setChartData(prevState => ({
      ...prevState,
      selection: timeline
    }));

    switch (timeline) {
      case '1Y':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date().setMonth(new Date().getMonth() - 1),
          new Date().getTime()
        )
        break;
      case 'six_months':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date('27 Sep 2012').getTime(),
          new Date().getTime()
        )
        break;
      case '1f':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date('27 Feb 2012').getTime(),
          new Date().getTime()
        )
        break;
      case 'ytd':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date('01 Jan 2013').getTime(),
          new Date().getTime()
        )
        break;
      case 'all':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date('23 Jan 2012').getTime(),
          new Date().getTime()
        )
        break;
      default:
    }
  }
  const Completionist = () => <span>You are good to go!</span>;
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return <div className={`${cascading.timersec}`}>

        <div className={`${cascading.card}`}>
          <p className={`${cascading.value}`}>{days}</p>
          <p className={`${cascading.label}`}>DAYS</p>
        </div>
        <div  className={`${cascading.card}`}>
          <p className={`${cascading.value}`}>{hours}</p>
          <p  className={`${cascading.label}`}>HOURS</p>
        </div>
        <div  className={`${cascading.card}`}>
          <p className={`${cascading.value}`}>{minutes}</p>
          <p  className={`${cascading.label}`}>MINS</p>
        </div>
        <div  className={`${cascading.card}`}>
          <p className={`${cascading.value}`}>{seconds}</p>
          <p  className={`${cascading.label}`}>SECS</p>
        </div>
                
      
      
      </div>


      // <span>{days}:{hours}:{minutes}:{seconds}</span>;
    }
  };
  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>

      
              <div className={`${cascading.innercontent} ${cascading.memberdashpage}`}>
                {/* <p className={`${cascading.pagehead} `}>Trading Information</p> */}
                <div className={`${cascading.topheadrow} row mx-0`}>
                  <div className={`col-lg-2 ${cascading.leftsec}`}>
                    <p className={`${cascading.pageheads}`}>Member Dashboard</p>
                  </div>
                  <div className={`col-lg-6 ${cascading.centersec}`}>
                    <p className={`${cascading.label}`}>Search :</p>
                    <div className={`${cascading.tablesec}`}>
                      <div className={`${cascading.filtersec}`}>
                        <DateRangePicker startDate={startDate} endDate={endDate} onChange={handleDateChange} />
                      </div>
                    </div>

                  </div>
                  <div className={`col-lg-4 ${cascading.rightsec}`}>
                    <div className={`${cascading.iconsec}`}>  
                      <img src={progress} className={`img-fluid ${cascading.icon}`} alt='progress'/>
                      <p className={`${cascading.name}`}>Trade Period</p>
                    </div>
                    <div className={`${cascading.timersec}`}>
                    <img src={timerline} className={`img-fluid ${cascading.timerline}`} alt='timerline'/>
                    <Countdown
                    date={Date.now() + 5000000000}
                    renderer={renderer}
                  />
                    </div>

                  </div>


                </div>
                <div className={`row ${cascading.chartrow}`}>
                  <div className={`col-12`}>
                    <div className={`${cascading.chartsec}`}>
                      <div id="chartmain1">
                        <div className={`${cascading.filterchart}`}>
                          {filterdate.map((e) => (
                            <button id={e.label} onClick={() => updateData(e.label)} className={chartData.selection === e.label ? `${cascading.filterbtn} btn ${cascading.active}` : `${cascading.filterbtn} btn`}>{e.label}</button>
                          ))}
                          {/* <button id="one_month" onClick={() => updateData('one_month')} className={chartData.selection === 'one_month' ? 'active' : ''}>1M</button>
                          <button id="six_months" onClick={() => updateData('six_months')} className={chartData.selection === 'six_months' ? 'active' : ''}>6M</button>
                          <button id="one_year" onClick={() => updateData('one_year')} className={chartData.selection === 'one_year' ? 'active' : ''}>1Y</button>
                          <button id="ytd" onClick={() => updateData('ytd')} className={chartData.selection === 'ytd' ? 'active' : ''}>YTD</button>
                          <button id="all" onClick={() => updateData('all')} className={chartData.selection === 'all' ? 'active' : ''}>ALL</button> */}
                        </div>
                        <div id="chart-timeline">
                          <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} />
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className={`col-md-6 `}>
                    <div className={`${cascading.chartsec}`}>
                      <div className={`${cascading.balrow}`}>
                        <div>
                          <p className={`${cascading.label}`}>Daily PnL <GrStatusInfo /></p>
                        </div>
                        <div className={`${cascading.rightsec}`}>
                          <p className={`${cascading.labels}`}>Today's PnL</p>
                          <p className={`${cascading.value}`}>$0</p>
                        </div>

                      </div>
                      <div id="chartmain2">
                        <div id="chart-timeline">
                          <ReactApexChart options={chartData1.options} series={chartData1.series} type="area" height={350} />
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className={`col-md-6 `}>
                    <div className={`${cascading.chartsec}`}>
                      <div className={`${cascading.balrow}`}>
                        <div>
                          <p className={`${cascading.label}`}>Assets Trend <GrStatusInfo /></p>
                        </div>
                        <div className={`${cascading.rightsec}`}>
                          <p className={`${cascading.labels}`}>Today's Assets</p>
                          <p className={`${cascading.value}`}>$0</p>
                        </div>

                      </div>
                      <div id="chartmain3">
                        <div id="chart-timeline">
                          <ReactApexChart options={chartData2.options} series={chartData2.series} type="area" height={350} />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
              <Innerfooter />


            </div>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Memberdashboard