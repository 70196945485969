import React, { useState } from 'react';
import cascading from '../assests/scss/userlist.module.scss'
import pfimg from '../assests/images/pfimg1.png';
import { CiSearch } from "react-icons/ci";

function Userlist() {
    const [searchTerm, setSearchTerm] = useState('');
    const [datalist, setDatalist] = useState([
        {
            userimg: pfimg,
            username: "user1",
            days: "10"
        },
        {
            userimg: pfimg,
            username: "user2",
            days: "10"
        },
        {
            userimg: pfimg,
            username: "user3",
            days: "10"
        },
        {
            userimg: pfimg,
            username: "user4",
            days: "10"
        },
        {
            userimg: pfimg,
            username: "user5",
            days: "10"
        },
        {
            userimg: pfimg,
            username: "user6",
            days: "10"
        },
        {
            userimg: pfimg,
            username: "user7",
            days: "10"
        },
        {
            userimg: pfimg,
            username: "user8",
            days: "10"
        },
        {
            userimg: pfimg,
            username: "user9",
            days: "10"
        },
        {
            userimg: pfimg,
            username: "user10",
            days: "10"
        },
        {
            userimg: pfimg,
            username: "user11",
            days: "10"
        },
        {
            userimg: pfimg,
            username: "user12",
            days: "10"
        },
        {
            userimg: pfimg,
            username: "user13",
            days: "10"
        },
        {
            userimg: pfimg,
            username: "user14",
            days: "10"
        },
        {
            userimg: pfimg,
            username: "user15",
            days: "10"
        },


    ]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredData = datalist.filter(item =>
        item.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={`${cascading.userlistsec}`}>
            <div className={`${cascading.inputsec}`}>
                <div className={`${cascading.inputinside}`}> 
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                   <CiSearch />
                </div>
             
            </div>
            <ul className={`${cascading.userlisted}`}>
                {datalist.map(e => (
                    <li key={e.id}>
                        <div className={`${cascading.leftsec}`}>
                            <img src={e.userimg} className={`img-fluid ${cascading.userimg}`} alt='pf' />
                            <p className={`${cascading.name}`}>{e.username}</p>

                        </div>
                        <div className={`${cascading.rightsec}`}>
                            <p className={`${cascading.days}`}>{e.days} days</p>
                        </div>

                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Userlist