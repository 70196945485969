import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import ApexCharts from 'apexcharts'
import Innerfooter from '../common/innerfooter';
import ReactApexChart from 'react-apexcharts';
import menuicon from '../assests/images/menuicon.svg'
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { BiSolidCheckCircle } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
function Console() {

  const [chartData1, setChartData1] = useState({
    series: [{
      name: 'Profit',
      data: [31, 40, 28, 51, 42, 109, 100],


    }],
    options: {
      colors: ['#FAC749', '#FAC749'],
      chart: {
        height: 250,
        type: 'bar',
      },
      dataLabels: {
        enabled: false
      },
      // stroke: {
      //   curve: 'smooth'
      // },

      xaxis: {
        type: 'datetime',
        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"],
        labels: {
          style: {
            colors: '#7D6B48'
          }
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      yaxis: {
        // opposite: true,
        labels: {
          align: 'right',
          style: {
            colors: '#7D6B48'
          }
        },

      },
    },

  });
  const [chartData2, setChartData2] = useState({
    series: [{
      name: 'Profit',
      data: [31, 40, 28, 51, 42, 109, 100],


    }],
    options: {
      colors: ['#FAC749', '#FAC749'],
      chart: {
        height: 250,
        type: 'bar',
      },
      dataLabels: {
        enabled: false
      },
      // stroke: {
      //   curve: 'smooth'
      // },

      xaxis: {
        type: 'datetime',
        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"],
        labels: {
          style: {
            colors: '#7D6B48'
          }
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      yaxis: {
        // opposite: true,
        labels: {
          align: 'right',
          style: {
            colors: '#7D6B48'
          }
        },

      },
    },

  });
  const [chartData3, setChartData3] = useState({
    series: [{
      name: 'Profit',
      data: [31, 40, 28, 51, 42, 109, 100],


    }],
    options: {
      colors: ['#FAC749', '#FAC749'],
      chart: {
        height: 250,
        type: 'bar',
      },
      dataLabels: {
        enabled: false
      },
      // stroke: {
      //   curve: 'smooth'
      // },

      xaxis: {
        type: 'datetime',
        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"],
        labels: {
          style: {
            colors: '#7D6B48'
          }
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      yaxis: {
        // opposite: true,
        labels: {
          align: 'right',
          style: {
            colors: '#7D6B48'
          }
        },

      },
    },

  });
  const [chartData4, setChartData4] = useState({
    series: [{
      name: 'Profit',
      data: [31, 40, 28, 51, 42, 109, 100],


    }],
    options: {
      colors: ['#FAC749', '#FAC749'],
      chart: {
        height: 250,
        type: 'bar',
      },
      dataLabels: {
        enabled: false
      },
      // stroke: {
      //   curve: 'smooth'
      // },

      xaxis: {
        type: 'datetime',
        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"],
        labels: {
          style: {
            colors: '#7D6B48'
          }
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      yaxis: {
        // opposite: true,
        labels: {
          align: 'right',
          style: {
            colors: '#7D6B48'
          }
        },

      },
    },

  });
  const [chartData5, setChartData5] = useState({
    series: [{
      name: 'Profit',
      data: [31, 40, 28, 51, 42, 109, 100],


    }],
    options: {
      colors: ['#FAC749', '#FAC749'],
      chart: {
        height: 250,
        type: 'bar',
      },
      dataLabels: {
        enabled: false
      },
      // stroke: {
      //   curve: 'smooth'
      // },

      xaxis: {
        type: 'datetime',
        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"],
        labels: {
          style: {
            colors: '#7D6B48'
          }
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      yaxis: {
        // opposite: true,
        labels: {
          align: 'right',
          style: {
            colors: '#7D6B48'
          }
        },

      },
    },

  });
  const [chartData6, setChartData6] = useState({
    series: [{
      name: 'Profit',
      data: [31, 40, 28, 51, 42, 109, 100],


    }],
    options: {
      colors: ['#FAC749', '#FAC749'],
      chart: {
        height: 250,
        type: 'bar',
      },
      dataLabels: {
        enabled: false
      },
      // stroke: {
      //   curve: 'smooth'
      // },

      xaxis: {
        type: 'datetime',
        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"],
        labels: {
          style: {
            colors: '#7D6B48'
          }
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      yaxis: {
        // opposite: true,
        labels: {
          align: 'right',
          style: {
            colors: '#7D6B48'
          }
        },

      },
    },

  });
  const [memrecent, setMemrecent] = useState([{
    id: "aaa1234",
    mjoin: "success",
    mjoin: "success",
    exjoin: "success",
    apijoin: "success",
    transaction: "failue"
  },
  {
    id: "aaa1234",
    mjoin: "success",
    mjoin: "success",
    exjoin: "success",
    apijoin: "success",
    transaction: "failue"
  },
  ])

  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>
              <div className={`${cascading.innercontent} ${cascading.consolepage}`}>
                <div className={`row`}>
                  <div className={`col-lg-5`}>
                    <div className={`${cascading.detailsec}`}>
                      <div className={`row`}>
                        <div className={`col-md-5 ${cascading.ratesec} mb-3`}>
                          <div className={`${cascading.topsec}`}>
                            <p className={`${cascading.head}`}>My commission rate</p>
                          </div>
                          <div className={`${cascading.btmsec}`}>
                            <div>
                              <p className={`${cascading.labelhead}`}>Share</p>
                              <p className={`${cascading.labelvalue}`}>50%</p>
                            </div>
                            <div>
                              <p className={`${cascading.labelhead}`}>Subscription</p>
                              <p className={`${cascading.labelvalue}`}>50%</p>
                            </div>

                          </div>

                        </div>
                        <div className={`col-md-7 ${cascading.referralsec} mb-3`}>
                          <div className={`${cascading.topsec}`}>
                            <p className={`${cascading.head}`}>Referral link</p>
                          </div>
                          <div className={`${cascading.btmsec}`}>
                            <div>
                              <p className={`${cascading.labelhead}`}>Click the Link to invite your friend</p>
                              <p className={`${cascading.labelvalue}`}>https://asdasdfasdsdf/asasd</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={` ${cascading.memsec} mb-3`}>
                        <div className={`row mx-0`}>
                          <div className={`col-12 ${cascading.leftsec}`}>
                            <p className={`${cascading.labelname}`}>Total membership</p>
                            <p className={`${cascading.value}`}>10,000 <span>People</span></p>
                          </div>
                          <div className={`col-12 ${cascading.rightsec}`}>
                            <ul>
                              <li>
                                <p className={`${cascading.listhead}`}>Partners</p>
                                <p className={`${cascading.listvalue}`}>500p</p>
                              </li>
                              <li>
                                <p className={`${cascading.listhead}`}>Distributor</p>
                                <p className={`${cascading.listvalue}`}>100p</p>
                              </li>
                              <li>
                                <p className={`${cascading.listhead}`}>Dealer</p>
                                <p className={`${cascading.listvalue}`}>1000p</p>
                              </li>

                              <li>
                                <p className={`${cascading.listhead}`}>Member</p>
                                <p className={`${cascading.listvalue}`}>50000p</p>
                              </li>
                              <li>
                                <p className={`${cascading.listhead}`}>Trading Member</p>
                                <p className={`${cascading.listvalue}`}>4000p</p>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>
                      <div className={`${cascading.tablesec}`}>
                        <div className={`${cascading.toprow}`}>
                          <p className={`${cascading.label}`}>Recent Membership</p>
                          <Link to="">More</Link>
                        </div>
                        <div className={`${cascading.tablestyle} mt-2`}>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>M-join</th>
                                <th>Ex-join</th>
                                <th>API-join</th>
                                <th>Transaction</th>
                              </tr>
                            </thead>
                            <tbody>
                              {memrecent.map((e) => (
                                <tr>
                                  <td>{e.id}</td>
                                  <td>{e.mjoin == "success" ? <BiSolidCheckCircle fill="#57C644" fontSize={18} /> : <MdCancel fill="#FC3D3D" fontSize={18} />} </td>
                                  <td>{e.exjoin == "success" ? <BiSolidCheckCircle fill="#57C644" fontSize={18} /> : <MdCancel fill="#FC3D3D" fontSize={18} />} </td>
                                  <td>{e.apijoin == "success" ? <BiSolidCheckCircle fill="#57C644" fontSize={18} /> : <MdCancel fill="#FC3D3D" fontSize={18} />} </td>
                                  <td>{e.transaction == "success" ? <BiSolidCheckCircle fill="#57C644" fontSize={18} /> : <MdCancel fill="#FC3D3D" fontSize={18} />} </td>
                                </tr>
                              ))}


                            </tbody>
                          </Table>
                        </div>

                      </div>
                      <div className={`row mt-4 ${cascading.btmsec}`}>
                        <div className={`col-md-6 mb-3`}>
                          <div className={`${cascading.balancecard}`}>
                            <div className={`${cascading.toprow}`}>
                              <p>Exchange-Deposit & Withdraw</p>
                            </div>
                            <div className={`${cascading.btmrow}`}>
                              {/* <p>Total</p> */}
                              <div>
                                <p>Total Deposit</p>
                                <p>9,000,000</p>
                              </div>
                              <div>
                                <p>Total Withdraw</p>
                                <p>9,000,000</p>
                              </div>
                            </div>

                          </div>

                        </div>
                        <div className={`col-md-6 mb-3`}>
                          <div className={`${cascading.balancecard}`}>
                            <div className={`${cascading.toprow}`}>
                              <p>TRD Power Charge</p>
                            </div>
                            <div className={`${cascading.btmrow}`}>
                              <p>Deposit</p>

                              <p>9,000,000 USDT</p>

                            </div>

                          </div>

                        </div>
                        <div className={`col-md-6 mb-3`}>
                          <div className={`${cascading.balancecard}`}>
                            <div className={`${cascading.toprow}`}>
                              <p>API Stop</p>
                            </div>
                            <div className={`${cascading.btmrow}`}>
                              <p>Total</p>

                              <p>30,000 p</p>

                            </div>

                          </div>

                        </div>
                        <div className={`col-md-6 mb-3`}>
                          <div className={`${cascading.balancecard}`}>
                            <div className={`${cascading.toprow}`}>
                              <p>M-subscription payment</p>
                            </div>
                            <div className={`${cascading.btmrow}`}>
                              <p>Deposit</p>

                              <p>9,000,000 USDT</p>

                            </div>

                          </div>

                        </div>
                        <div className={`col-md-6 mb-3`}>
                          <div className={`${cascading.balancecard}`}>
                            <div className={`${cascading.toprow}`}>
                              <p>M-Withdraw</p>
                            </div>
                            <div className={`${cascading.btmrow}`}>
                              <p>withdraw</p>

                              <p>9,000,000 USDT</p>

                            </div>

                          </div>

                        </div>
                      </div>

                    </div>

                  </div>
                  <div className={`col-lg-7`}>
                    <div className={`row`}>
                      <div className={`col-lg-6 mb-3`}>
                        <div className={`${cascading.graphsec}`}>
                          <div className={`${cascading.toprow}`}>
                            <div>
                              <p className={`${cascading.label}`}>USDT PnL Status</p>
                              <p className={`${cascading.value}`}>9,000,000,000 USDT</p>
                            </div>
                            <div>
                              <img src={menuicon} className={`img-fluid ${cascading.menuicon}`} alt='menuicon' />
                            </div>
                          </div>
                          <div id="chart-timeline">
                            <ReactApexChart options={chartData1.options} series={chartData1.series} type="bar" height={250} />
                          </div>
                        </div>

                      </div>
                      <div className={`col-lg-6 mb-3`}>
                        <div className={`${cascading.graphsec}`}>
                          <div className={`${cascading.toprow}`}>
                            <div>
                              <p className={`${cascading.label}`}>USDT Increase quantity</p>
                              <p className={`${cascading.value}`}>9,000,000,000 USDT</p>
                            </div>
                            <div>
                              <img src={menuicon} className={`img-fluid ${cascading.menuicon}`} alt='menuicon' />
                            </div>
                          </div>
                          <div id="chart-timeline">
                            <ReactApexChart options={chartData2.options} series={chartData2.series} type="bar" height={250} />
                          </div>
                        </div>

                      </div>
                      <div className={`col-lg-6 mb-3`}>
                        <div className={`${cascading.graphsec}`}>
                          <div className={`${cascading.toprow}`}>
                            <div>
                              <p className={`${cascading.label}`}>USDT Total Volume</p>
                              <p className={`${cascading.value}`}>9,000,000,000 USDT</p>
                            </div>
                            <div>
                              <img src={menuicon} className={`img-fluid ${cascading.menuicon}`} alt='menuicon' />
                            </div>
                          </div>
                          <div id="chart-timeline">
                            <ReactApexChart options={chartData3.options} series={chartData3.series} type="bar" height={250} />
                          </div>
                        </div>

                      </div>
                      <div className={`col-lg-6 mb-3`}>
                        <div className={`${cascading.graphsec}`}>
                          <div className={`${cascading.toprow}`}>
                            <div>
                              <p className={`${cascading.label}`}>USDT PnL Status</p>
                              <p className={`${cascading.value}`}>9,000,000,000 USDT</p>
                            </div>
                            <div>
                              <img src={menuicon} className={`img-fluid ${cascading.menuicon}`} alt='menuicon' />
                            </div>
                          </div>
                          <div id="chart-timeline">
                            <ReactApexChart options={chartData4.options} series={chartData4.series} type="bar" height={250} />
                          </div>
                        </div>

                      </div>
                      <div className={`col-lg-6 mb-3`}>
                        <div className={`${cascading.graphsec}`}>
                          <div className={`${cascading.toprow}`}>
                            <div>
                              <p className={`${cascading.label}`}>Subscription profit</p>
                              <p className={`${cascading.value}`}>9,000,000,000 USDT</p>
                            </div>
                            <div>
                              <img src={menuicon} className={`img-fluid ${cascading.menuicon}`} alt='menuicon' />
                            </div>
                          </div>
                          <div id="chart-timeline">
                            <ReactApexChart options={chartData5.options} series={chartData5.series} type="bar" height={250} />
                          </div>
                        </div>

                      </div>
                      <div className={`col-lg-6 mb-3`}>
                        <div className={`${cascading.graphsec}`}>
                          <div className={`${cascading.toprow}`}>
                            <div>
                              <p className={`${cascading.label}`}>TRD Charging Profit</p>
                              <p className={`${cascading.value}`}>9,000,000,000 USDT</p>
                            </div>
                            <div>
                              <img src={menuicon} className={`img-fluid ${cascading.menuicon}`} alt='menuicon' />
                            </div>
                          </div>
                          <div id="chart-timeline">
                            <ReactApexChart options={chartData6.options} series={chartData6.series} type="bar" height={250} />
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>



                <div>

                </div>
              </div>


            </div>
            <Innerfooter />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Console