import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import Select from 'react-select';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useDemoData } from "@mui/x-data-grid-generator";
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import { CiSearch } from 'react-icons/ci';
import DateRangePicker from '../components/datepicker';
import { BiSolidCheckCircle } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import Dropdown from 'react-bootstrap/Dropdown';
import Innerfooter from '../common/innerfooter';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import StyledDataGrid from '../common/StyleDataGrid';
const options = [
  { value: 'us', label: 'US' },
  { value: 'korea', label: 'korea' },
  { value: 'japan', label: 'Japan' },
  { value: 'india', label: 'India' },
];


function Apiinfo() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedStatus, setSelectedstatus] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };
  const columns = [
    { field: 'sno', headerName: 'sno', minWidth: 60, flex: 1 },
    { field: 'ids', headerName: 'ID', type: 'Date', minWidth: 150, flex: 1 },
    { field: 'uid', headerName: 'UID', type: 'Date', minWidth: 150, flex: 1 },
    { field: 'name', headerName: 'Name', minWidth: 100, flex: 1 },
    { field: 'nation', headerName: 'Nation', minWidth: 150, flex: 1 },
    { field: 'toprcmd', headerName: 'Top RCMD', minWidth: 150, flex: 1 },
    { field: 'biztop', headerName: 'Biz Top', minWidth: 150, flex: 1 },
    { field: 'joinex', headerName: 'Join Ex', minWidth: 150, flex: 1 },
    { field: 'joinm', headerName: 'Join-M', minWidth: 150, flex: 1 },
    { field: 'api', headerName: 'Api connection', minWidth: 150, flex: 1, renderCell: (params) => <div className={`${cascading.labels}`}><BiSolidCheckCircle fill="#57C644" fontSize={28} /> </div> },
    { field: 'svr', headerName: 'SVR API', minWidth: 100, flex: 1, renderCell: (params) => <div className={`${cascading.labels}`}><MdCancel fill="#FC3D3D" fontSize={28} /> </div> },
    { field: 'trd', headerName: 'TRD API', minWidth: 100, flex: 1, renderCell: (params) => <div className={`${cascading.labels}`}><MdCancel fill="#FC3D3D" fontSize={28} /> </div> },
 
    { field: 'tokenid', headerName: 'Token ID', minWidth: 150, flex: 1 },
    { field: 'apikey', headerName: 'Api key', minWidth: 150, flex: 1 },
    {
      field: 'status', headerName: 'status', minWidth: 150, flex: 1, renderCell: (params) =>
        <div className='mui_tableselect'>
          <FormControl fullWidth>

            <MuiSelect
              labelId="demo-simple-select-label"
              id="demo-simple-select"
                // value={age}
                // onChange={handleChange}
                defaultValue={'normal'}
            >
              <MenuItem value={"normal"}>Normal</MenuItem>
              <MenuItem value={"start"}>Start</MenuItem>
              <MenuItem value={"stop"}>Stop</MenuItem>
              <MenuItem value={"trdstop"}>TRD stop</MenuItem>
              <MenuItem value={"svrstop"}>SVR Stop</MenuItem>
              <MenuItem value={"delete"}>Delete</MenuItem>
            </MuiSelect>
          </FormControl>
        </div>
    },
   
   
   


  ];

  const rows = [
    { id: 0, sno: 1000,ids:"sdsdfsdssdf", uid: '0123456789', name: 'SADSADsdfsdf', nation: 'Korea', toprcmd: 'Asdasda', biztop: 'PTN(1000)', joinex: '23.12.31',joinm:"23.12.31", api: '', svr: "", trd: "", tokenid: "9,000,000", apikey: "bg_000f31d900559c43ea4266046769430c", status: "" },
    { id: 0, sno: 1000,ids:"sdsdfsdssdf", uid: '0123456789', name: 'SADSADsdfsdf', nation: 'Korea', toprcmd: 'Asdasda', biztop: 'PTN(1000)', joinex: '23.12.31',joinm:"23.12.31", api: '', svr: "", trd: "", tokenid: "9,000,000", apikey: "bg_000f31d900559c43ea4266046769430c", status: "" },
   



  ];
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 1000,
    maxColumns: 4
  });
  const inputElement = useRef();



  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (

      <Pagination
        color="standard"
        shape="rounded"
        page={page + 1}
        // className={`${styles.pagecustom}`}
        count={pageCount}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />

    );
  }
  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>
              <div className={`${cascading.innercontent} ${cascading.memberpage}`}>
                <div className={`${cascading.topheadsec}`}>
                  <p className={`${cascading.pagehead}`}>API INFORMATION</p>
                  <p className={`${cascading.pagedesc}`}>trade , sell and hold 10 + cryptocurrencies on
                    Crypto</p>

                </div>
                <div className={`${cascading.topfilterrow} mt-4`}>
                  <div className={`${cascading.selectfiltersec} `}>
                    <div className={`${cascading.reactselect}`}>
                      <Select
                        className='reactselect_container'
                        classNamePrefix="reactselect"
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                        placeholder="US"
                      />
                    </div>
                    <button className={`${cascading.searchbtn} btn`}>Search</button>


                  </div>
              

                </div>
                <div className={`${cascading.tablesec} mt-4`}>
                  <div className={`${cascading.filtersec}`}>
                    <div className={`${cascading.inputinside}`}>
                      <input
                        type="text"
                        placeholder="Search..."
                      // value={searchTerm}
                      // onChange={handleSearchChange}
                      />
                      <CiSearch />
                    </div>
                    <DateRangePicker startDate={startDate} endDate={endDate} onChange={handleDateChange} />


                  </div>
                  <div id="styletable">
                    <DataGrid
                      ref={inputElement}
                      {...data}
                      disableColumnMenu
                      rows={rows}
                     rowSelection={false}
                      sx={{
                        ...StyledDataGrid(),
                      }}
                      columns={columns}

                      components={{
                        Pagination: CustomPagination,
                      }}
                    // slots={{
                    //   footer: CustomPagination,
                    // }}



                    />
                  </div>

                </div>
                <div>

                </div>
              </div>


            </div>
            <Innerfooter/>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Apiinfo