import React, { useEffect, useState } from 'react'
import cascading from '../assests/scss/sidebar.module.scss'
import logo from '../assests/images/logo.png';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { loginControl } from '../Redux/action';
import { useSelector, useDispatch } from 'react-redux';
import { IoClose } from "react-icons/io5";
import { MdMenu } from "react-icons/md";
// import goldline from '../assests/images/goldline.png'
import { toast } from 'react-toastify';
function Sidebar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState();
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  const [mbmenu, setMbmenu] = useState(false);
  const [sidemenu, setSidemenu] = useState([ {
    name: 'Console',
    path: "/console",
   
  },
  {
    name: 'Member',
    path: "/member",
   
  },
  {
    name: 'API',
    path: "/api-info",
   
  },
  {
    name: 'Partner',
    path: "/referral",
   
  },
  {
    name: 'Deposit',
    path: "/Deposit",
   
  },
  {
    name: 'Withdraw',
    path: "/Withdraw",
   
  },
  {
    name: 'SVR Power',
    path: "/SVR-Power",
   
  },
  {
    name: 'TRD Power',
    path: "/TRD-Power",
   
  },
  {
    name: 'Recommended settlement',
    path: "/Recommended-settlement",
   
  },
  {
    name: 'Settlement management',
    path: "/settlement",
   
  },

  {
    name: 'Member board',
    path: "/Member-board",
   
  },

  {
    name: 'Recommended  composition',
    path: "/Recommended-composition",
   
  },

  {
    name: 'Community',
    path: "/Community",
   
  },

  {
    name: 'Enquiry',
    path: "/Enquiry",
   
  },
  {
    name: 'Profile',
    path: "/profile",
   
  },


  
])
const logoutfn = ()=>{

  dispatch(loginControl({
    loginStatus: "logout",
}),

  );
  localStorage.setItem('loginstatus', 'logout');
  toast.success("Log out Successfully")
  
}
  return (
    <>
    <div className={`d-flex d-xl-none ${cascading.menubar}`}>
    <MdMenu onClick={() => setMbmenu(true)} />
     <div>
    <img src={logo} alt='logo' className={`img-fluid ${cascading.logoss}`} />
     </div>
   </div>
    <div className={`${cascading.sidebar}`}>
    
      <div className={windowWidth > 1199 ? `${cascading.sidebarsec}` : mbmenu ? `${cascading.sidebarsec} ${cascading.mobilemenu} ${cascading.mobilemenuopen}` : `${cascading.sidebarsec} ${cascading.mobilemenu}`}>

        <div className={`d-block d-xl-none ${cascading.closeicons}`}>
          <IoClose onClick={() => setMbmenu(false)} />
        </div>
        <div className={`${cascading.siderow}`}>
          <div className={`${cascading.topsec}`}>

         
          <div className={`${cascading.logosec}`}>
            <img src={logo} alt='logo' className={`img-fluid ${cascading.logoimg}`} />
          </div>
          {/* <img src={goldline} className={`img-fluid ${cascading.goldline}`} alt='goldline' /> */}
          </div>
        
          <ul className={`${cascading.sidelist}`}>
            {sidemenu.map((e) => (
              <li className={location.pathname == e.path ? `${cascading.activemenus}` : ''}>
                 <NavLink  to={e.path} >
                  <div className={`${cascading.iconrow}`}>
                    {/* <img src={e.img} alt='icon' className={`img-fluid ${cascading.icon}`} /> */}
                    <p className={`${cascading.headname}`}>{e.name}</p>
                  </div></NavLink></li>
            ))}
             <li onClick={()=>logoutfn()} >
                 <NavLink  to={"/"} >
                  <div className={`${cascading.iconrow}`}>
                    {/* <img src={require('../assests/images/sidebar/help.png')} alt='icon' className={`img-fluid ${cascading.icon}`} /> */}
                    <p className={`${cascading.headname}`}>Logout</p>
                  </div></NavLink></li>
           
          </ul>
        </div>

      </div>
    </div>
    </>
  )
}

export default Sidebar