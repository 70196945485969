import Apiinfo from "../pages/apiinfo";
import Community from "../pages/community";
import Console from "../pages/console";
import Deposit from "../pages/deposit";
import Enquiry from "../pages/enquiry";
import Login from "../pages/login";
import Member from "../pages/member";
import Memberdashboard from "../pages/memberdashboard";
import Profile from "../pages/profile";
import Settlement from "../pages/Settlement";
import Referral from "../pages/referral";
import Svrpower from "../pages/svrpower";
import Withdraw from "../pages/withdraw";
import Recommended from "../pages/recommended";
import TRDpower from "../pages/trdpower";
import RecommendedSettlement from "../pages/recommended_settlement";


const routes = [{
    path: '/',
    component: <Login/>,
  },
  {
    path: '/console',
    component: <Console/>,
  },
  {
    path: '/member',
    component: <Member/>,
  },
  {
    path: '/api-info',
    component: <Apiinfo/>,
  },
  {
    path: '/referral',
    component: <Referral/>,
  },
  {
    path: '/Deposit',
    component: <Deposit/>,
  },
  {
    path: '/Withdraw',
    component: <Withdraw/>,
  },
  {
    path: '/SVR-Power',
    component: <Svrpower/>,
  },
  {
    path: '/TRD-Power',
    component: <TRDpower/>,
  },
  {
    path: '/Recommended-settlement',
    component: <RecommendedSettlement/>,
  },
  {
    path: '/Community',
    component: <Community/>,
  },
  {
    path: '/Member-board',
    component: <Memberdashboard/>,
  },
  {
    path: '/settlement',
    component: <Settlement/>,
  },
  {
    path: '/Recommended-composition',
    component: <Recommended/>,
  },
  {
    path: '/Enquiry',
    component: <Enquiry/>,
  },
  {
    path: '/profile',
    component: <Profile/>,
  },


  

 ];
 
 export default routes;