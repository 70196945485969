import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from '../assests/scss/partnerregister.module.scss'
import { CiSearch } from 'react-icons/ci';
function PartnerRegister(props) {
    const [method, setMethod] = useState(true)
    return (
        <div>
            <Modal show={props.show} onHide={props.onClose} centered className={`${styles.editmodal}`}>
                <Modal.Header closeButton className={`${styles.modalhead}`}>
                    <Modal.Title className={`${styles.modaltitle}`}>Partner Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                      
                            <div className={`${styles.inputsec} mb-4`}>
                                <div className={`${styles.inputinside}`}>
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                    // value={searchTerm}
                                    // onChange={handleSearchChange}
                                    />
                                    <CiSearch />
                                </div>
                                <button className={`btn ${styles.searchbtn}`}>
                                    Search

                                </button>

                            </div>
                     
                        <div className={`${styles.inputrow} mb-4`}>
                            <p className={`${styles.inputlabel}`}>EMAIL / ID</p>
                            <div className={`${styles.ordinp}`}>
                                <input type="text" className={`form-control`} placeholder="" />

                            </div>

                        </div>
                        <div className={`row mb-3`}>
                            <p className={`${styles.rowhead} mb-3`}>Choice (Partner PR Method Setting)</p>
                            <div className={`col-md-6 mb-3`}>
                                <div className={`${styles.checkboxrow}`} onClick={() => setMethod(true)}>
                                    <div className={method ? `${styles.cirlce} ${styles.active}` : `${styles.cirlce}`}></div>
                                    <p className={`${styles.label}`}>Subscription payment</p>

                                </div>

                            </div>
                            <div className={`col-md-6 mb-3`}>
                                <div className={`${styles.checkboxrow}`} onClick={() => setMethod(false)}>
                                    <div className={!method ? `${styles.cirlce} ${styles.active}` : `${styles.cirlce}`}></div>
                                    <p className={`${styles.label}`}>Share(month)</p>

                                </div>

                            </div>

                        </div>

                        <div className={`row`}>
                            <p className={`${styles.rowhead}`}>Select Partner Rate</p>
                            <div className={`col-md-6`}>
                                <div className={`${styles.inputrow} mb-4`}>
                                    <p className={`${styles.inputlabel}`}>Subscription (Max 100%)</p>
                                    <div className={`${styles.ordinp}`}>
                                        <input type="text" className={`form-control`} placeholder="" />

                                    </div>

                                </div>
                            </div>
                            <div className={`col-md-6`}>
                                <div className={`${styles.inputrow} mb-4`}>
                                    <p className={`${styles.inputlabel}`}>Share (Max 100%)</p>
                                    <div className={`${styles.ordinp}`}>
                                        <input type="text" className={`form-control`} placeholder="" />

                                    </div>

                                </div>
                            </div>

                        </div>








                    </div>



                </Modal.Body>
                <Modal.Footer className={`${styles.modalfooter}`}>
                    <button className={`${styles.themebtn} btn`} onClick={props.onClose}>Confirmation</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PartnerRegister