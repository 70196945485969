
import './App.scss';
import { BrowserRouter, Routes, Route  } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import routes from './Routes/routes.jsx'
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const userdetail = useSelector((state) => state.admin)
  const routeComponents = routes.map(({path, component}, key) => <Route exact path={path} element={component} key={key} />);
  const [theme, setTheme] = useState(userdetail.themeStatus);
  console.log(userdetail.themeStatus,theme,"asdasd");
  useEffect(() => {
    if (userdetail.themeStatus == "dark") {
      document.body.classList.add("darktheme");
      document.body.classList.remove("lighttheme");
    } else {
      document.body.classList.remove("darktheme");
      document.body.classList.add("lighttheme");
    }

  }, [userdetail.themeStatus]);
  return (
       <div >
            <ToastContainer />
    <BrowserRouter>
    <Routes>
       {routeComponents}
       </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
