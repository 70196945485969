import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import dummypf from '../assests/images/dummypf.png';
import Dropdown from 'react-bootstrap/Dropdown';
import author from '../assests/images/authorpf.png'
import styles from '../assests/scss/enquirymodal.module.scss'
function Enquirymodal(props) {
    const [userqn, setUserqn] = useState([
        {
          pfimg: dummypf,
          name: "Peter",
          date: "29,Apr 2024",
          que: "How to go community pages ?"
    
        },
        {
          pfimg: dummypf,
          name: "Peter",
          date: "29,Apr 2024",
          que: "How to go community pages ?"
    
        }
      ])
     
    return (
        <div>
            <Modal show={props.show} onHide={props.onClose} centered className={`${styles.editmodal}`}>
                <Modal.Header closeButton className={`${styles.modalhead}`}>
                    <Modal.Title className={`${styles.modaltitle}`}>Replies</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                    {userqn.map((e) => (
                    <div className={`${styles.usercard}`}>
                      <div className={`${styles.toprow}`}>
                        <div className={`${styles.leftpfsec}`}>
                          <div>
                            <img src={e.pfimg} className={`img-fluid ${styles.pfimg}`} alt='img' />
                          </div>
                          <div>
                            <p className={`${styles.name}`}>{e.name}</p>
                            <p className={`${styles.date}`}>{e.date}</p>
                          </div>
                        </div>
                     
                      </div>
                      <div className={`${styles.qnsec}`}>
                        <p className={`${styles.ques}`}>{e.que}</p>
                        <p className={`${styles.reply}`}>1 Reply</p>

                        </div>
                    </div>
                  ))}
                      
                        
                    </div>



                </Modal.Body>
                <Modal.Footer className={`${styles.modalfooter}`}>
                    <div className={`${styles.leftsec}`}>

                    <img src={author} className={`img-fluid `} alt='authorimg'/>
                    <input type="email" className={`form-control ${styles.rplyinpt}`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter replies"/>
                    </div>
                 <button className={`${styles.themebtn} btn`} >Send</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Enquirymodal