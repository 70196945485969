import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import Select from 'react-select';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useDemoData } from "@mui/x-data-grid-generator";
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import { CiSearch } from 'react-icons/ci';
import DateRangePicker from '../components/datepicker';
import { BiSolidCheckCircle } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import Dropdown from 'react-bootstrap/Dropdown';
import Innerfooter from '../common/innerfooter';
import Table from 'react-bootstrap/Table';

const options = [
  { value: 'normal', label: 'Normal' },
  { value: 'stop', label: 'Stop' },
  { value: 'out', label: 'Out' },
  { value: 'noaccess', label: 'No access' },
];

const status = [
  { value: 'normal', label: 'Normal' },
  { value: 'stop', label: 'Stop' },
  { value: 'out', label: 'Out' },
  { value: 'noaccess', label: 'No access' },
];
function Settlement() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedStatus, setSelectedstatus] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };


  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>
              <div className={`${cascading.innercontent} ${cascading.settlementpage}`}>
                <div className={`${cascading.topheadsec}`}>
                  <p className={`${cascading.pagehead}`}> Settlement Management</p>
                  <p className={`${cascading.pagedesc}`}>trade , sell and hold 10 + cryptocurrencies on
                    Crypto</p>

                </div>

                <div className={`${cascading.tablesec} mt-4`}>
                  <div className={`${cascading.filtersec}`}>
                    <div className={`${cascading.inputinside}`}>
                      <input
                        type="text"
                        placeholder="Search..."
                      // value={searchTerm}
                      // onChange={handleSearchChange}
                      />
                      <CiSearch />
                    </div>
                    <DateRangePicker startDate={startDate} endDate={endDate} onChange={handleDateChange} />


                  </div>


                </div>
                <div className={`row mt-3`}>
                  <div className={`col-md-8 mb-3`}>
                    <div className={`${cascading.cardstyle}`}>
                      <p className={`${cascading.cardhead}`}>Member Integration Data</p>

                      <div>
                        <Table striped responsive className={`${cascading.tablestyles}`}>

                          <tbody>
                            <tr>
                              <td>Ex-join</td>
                              <td>Ex-Total Deposit</td>
                              <td>Ex-Total Balance</td>
                              <td>VVIP Total Deposit</td>
                              <td>Numbers of partners</td>
                            </tr>
                            <tr>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>1,000</td>
                            </tr>
                            <tr>
                              <td>TRD API</td>
                              <td>Ex-Number of Deposit</td>
                              <td>Ex-Number of transaction</td>
                              <td>User referral deposit</td>
                              <td>Number of recommended members</td>
                            </tr>
                            <tr>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                            </tr>
                            <tr>
                              <td>Connection API</td>
                              <td>Ex-Total Withdraw</td>
                              <td>USDT Actual transaction quantity</td>
                              <td>NM Total Deposit</td>
                              <td>Number of Dealer</td>
                            </tr>
                            <tr>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                            </tr>
                            <tr>
                              <td>TRD API</td>
                              <td>Ex-NUmber of Deposit</td>
                              <td>Ex-Number of transaction</td>
                              <td>User referral deposit</td>
                              <td>Number of recommended members</td>
                            </tr>
                            <tr>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>

                    </div>

                  </div>
                  <div className={`col-md-4 mb-3`}>
                    <div className={`${cascading.cardstyle}`}>
                      <p className={`${cascading.cardhead}`}>SVR Charging Status</p>

                      <div>
                        <Table striped responsive className={`${cascading.tablestyles}`}>

                          <tbody>
                            <tr>
                              <td>SVR Deposit Scheduled</td>
                              <td>SVR Number of deposit</td>

                            </tr>
                            <tr>
                              <td>9,000,000</td>
                              <td>9,000,000</td>

                            </tr>
                            <tr>
                              <td>SVR Deposit Quantity</td>
                              <td></td>

                            </tr>
                            <tr>
                              <td>9,000,000</td>
                              <td></td>

                            </tr>
                            <tr>
                              <td>SVR Non Deposit</td>
                              <td></td>

                            </tr>
                            <tr>
                              <td>9,000,000</td>
                              <td></td>

                            </tr>
                            <tr>
                              <td>SVR API Stop</td>
                              <td></td>

                            </tr>
                            <tr>
                              <td>9,000,000</td>
                              <td></td>

                            </tr>
                          </tbody>
                        </Table>
                      </div>

                    </div>

                  </div>
                </div>
                <div className={`row mt-3`}>
                  <div className={`col-md-10 mb-3`}>
                    <div className={`${cascading.cardstyle}`}>
                      <p className={`${cascading.cardhead}`}>Subscription payment integrated data</p>

                      <div>
                        <Table striped responsive className={`${cascading.tablestyles}`}>

                          <tbody>
                            <tr>
                              <td>1 months payment</td>
                              <td>M-number of payments</td>
                              <td>Partner Fee</td>
                              <td>Subscription deposit</td>
                            </tr>
                            <tr>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>

                            </tr>
                            <tr>
                              <td>3 months payment</td>
                              <td>Number of payments per 3 months</td>
                              <td>Distributor Fee</td>
                              <td>Subscription Fee</td>

                            </tr>
                            <tr>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>


                            </tr>
                            <tr>
                              <td>6 months payment</td>
                              <td>Number of payments per 6 months</td>
                              <td>Dealer Fee</td>
                              <td></td>


                            </tr>
                            <tr>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td></td>

                            </tr>
                            <tr>
                              <td>12 months payment</td>
                              <td>Number of payments per 12 months</td>
                              <td>User referral fee</td>
                              <td>Subscription Profit</td>
                            </tr>
                            <tr>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>
                              <td>9,000,000</td>

                            </tr>
                          </tbody>
                        </Table>
                      </div>

                    </div>

                  </div>
                  <div className={`col-md-2 mb-3`}>
                    <div className={`${cascading.cardstyle}`}>
                      <p className={`${cascading.cardhead}`}>TRD Gas Charging Status</p>

                      <div>
                        <Table striped responsive className={`${cascading.tablestyles}`}>

                          <tbody>
                            <tr>
                              <td>TRD deposit
                                Quantity</td>


                            </tr>
                            <tr>
                              <td>9,000,000</td>


                            </tr>
                            <tr>
                              <td>TRD
                                Current Balance</td>


                            </tr>
                            <tr>
                              <td>9,000,000</td>


                            </tr>
                            <tr>
                              <td>TRD Closing
                                Quantity</td>


                            </tr>
                            <tr>
                              <td>9,000,000</td>


                            </tr>
                            <tr>
                              <td>TRD Total
                                Profit</td>
                          

                            </tr>
                            <tr>
                              <td>9,000,000</td>
                           

                            </tr>
                          </tbody>
                        </Table>
                      </div>

                    </div>

                  </div>
                </div>
                <div>

                </div>
              </div>


            </div>
            <Innerfooter />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Settlement