import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid';
import Select from 'react-select';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useDemoData } from "@mui/x-data-grid-generator";
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import { CiSearch } from 'react-icons/ci';
import DateRangePicker from '../components/datepicker';
import { BiSolidCheckCircle } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import Dropdown from 'react-bootstrap/Dropdown';
import Innerfooter from '../common/innerfooter';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import StyledDataGrid from '../common/StyleDataGrid';
const options = [
  { value: 'normal', label: 'Normal' },
  { value: 'stop', label: 'Stop' },
  { value: 'out', label: 'Out' },
  { value: 'noaccess', label: 'No access' },
];

const status = [
  { value: 'normal', label: 'Normal' },
  { value: 'stop', label: 'Stop' },
  { value: 'out', label: 'Out' },
  { value: 'noaccess', label: 'No access' },
];
function Referral() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedStatus, setSelectedstatus] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };
  const columns = [
    { field: 'sno', headerName: 'sno', minWidth: 60, flex: 1 },
    { field: 'ids', headerName: 'ID', type: 'Date', minWidth: 150, flex: 1 },
    { field: 'uid', headerName: 'UID', type: 'Date', minWidth: 150, flex: 1 },
    { field: 'name', headerName: 'Name', minWidth: 100, flex: 1 },
    { field: 'nation', headerName: 'Nation', minWidth: 150, flex: 1 },
    { field: 'phone', headerName: 'Phone', minWidth: 150, flex: 1 },
    { field: 'level', headerName: 'Member Level', minWidth: 150, flex: 1 },
    { field: 'partner', headerName: 'Partner', minWidth: 150, flex: 1 },
    { field: 'distributor', headerName: 'Distributor', minWidth: 150, flex: 1 },
    { field: 'dealer', headerName: 'Dealer', minWidth: 150, flex: 1,  },
    { field: 'recommendation', headerName: 'Recommendation', minWidth: 100, flex: 1, },
    { field: 'usdtbalance', headerName: 'USDT Balance', minWidth: 100, flex: 1 },
 
    { field: 'mylocation', headerName: 'My Location', minWidth: 150, flex: 1 },
    { field: 'fee', headerName: 'Subcription Fee', minWidth: 150, flex: 1,renderCell: (params) => <div className={`${cascading.labels}`}><BiSolidCheckCircle fill="#57C644" fontSize={28} />
    {/* <MdCancel fill="#FC3D3D" fontSize={28} />  */}
     </div> },
    { field: 'trading', headerName: 'Trading', minWidth: 150, flex: 1 ,renderCell: (params) => <div className={`${cascading.labels}`}><BiSolidCheckCircle fill="#57C644" fontSize={28} /> </div>},
    {
      field: 'status', headerName: 'status', minWidth: 150, flex: 1, renderCell: (params) =>
        <div className='mui_tableselect'>
          <FormControl fullWidth>

            <MuiSelect
              labelId="demo-simple-select-label"
              id="demo-simple-select"
                // value={age}
                // onChange={handleChange}
                defaultValue={'normal'}
            >
              <MenuItem value={"normal"}>Normal</MenuItem>
              <MenuItem value={"stop"}>Stop</MenuItem>
        
            </MuiSelect>
          </FormControl>
        </div>
    },
   
   
   


  ];

  const rows = [
    { id: 0, sno: 1000,ids:"sdsdfsdssdf", uid: '0123456789', name: 'SADSADsdfsdf', nation: 'Korea', phone: '+82 010 1234 1234', level: 'VVIP', partner: 'aaaaaa (10000)',distributor:"bbbbb (1000)", dealer: 'cccc (1000)', recommendation: "dddd (1000)", usdtbalance: "9,000,000", mylocation: "Mscore", fee: "",trading:"", status: "" },
    { id: 1, sno: 1000,ids:"sdsdfsdssdf", uid: '0123456789', name: 'SADSADsdfsdf', nation: 'Korea', phone: '+82 010 1234 1234', level: 'VVIP', partner: 'aaaaaa (10000)',distributor:"bbbbb (1000)", dealer: 'cccc (1000)', recommendation: "dddd (1000)", usdtbalance: "9,000,000", mylocation: "Mscore", fee: "",trading:"", status: "" },
    
   



  ];
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 1000,
    maxColumns: 4
  });
  const inputElement = useRef();


  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (

      <Pagination
        color="standard"
        shape="rounded"
        page={page + 1}
        // className={`${styles.pagecustom}`}
        count={pageCount}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />

    );
  }
  return (
    <>
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>
              <div className={`${cascading.innercontent} ${cascading.memberpage}`}>
                <div className={`${cascading.topheadsec}`}>
                  <p className={`${cascading.pagehead}`}>Referral Management</p>
                  <p className={`${cascading.pagedesc}`}>trade , sell and hold 10 + cryptocurrencies on
                    Crypto</p>

                </div>
                <div className={`${cascading.topfilterrow} mt-4`}>
                  <div className={`${cascading.selectfiltersec} `}>
                    <div className={`${cascading.reactselect}`}>
                      <Select
                        className='reactselect_container'
                        classNamePrefix="reactselect"
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                        placeholder="normal"
                      />
                    </div>
                    <button className={`${cascading.searchbtn} btn`}>Search</button>


                  </div>
              

                </div>
                <div className={`${cascading.tablesec} mt-4`}>
                  <div className={`${cascading.filtersec}`}>
                    <div className={`${cascading.inputinside}`}>
                      <input
                        type="text"
                        placeholder="Search..."
                      // value={searchTerm}
                      // onChange={handleSearchChange}
                      />
                      <CiSearch />
                    </div>
                    <DateRangePicker startDate={startDate} endDate={endDate} onChange={handleDateChange} />


                  </div>
                  <div id="styletable">
                    <DataGrid
                      ref={inputElement}
                      {...data}
                      disableColumnMenu
                      rows={rows}
                      rowSelection={false}
                      sx={{
                        ...StyledDataGrid(),
                      }}
                      columns={columns}

                      components={{
                        Pagination: CustomPagination,
                      }}
                    // slots={{
                    //   footer: CustomPagination,
                    // }}



                    />
                  </div>

                </div>
                <div>

                </div>
              </div>


            </div>
            <Innerfooter/>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Referral