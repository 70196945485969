import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import cascading from '../assests/scss/all.module.scss'
import Sidebar from '../common/Sidebar';
import Userlist from '../components/userlist';
import Createpost from '../components/createpost';
import Postsection from '../components/postsection';
import dummypage from '../assests/images/dummypage.png'
import Innerfooter from '../common/innerfooter';
function Community() {
  const [postsec, setPostsec] = useState(false)
  const [postdata, setPostdata] = useState([{
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    imgs: [{
      img: dummypage,
    },
    {
      img: dummypage,
    }, {
      img: dummypage,
    }]

  },
  {
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    imgs: [{
      img: dummypage,
    },
    {
      img: dummypage,
    }, {
      img: dummypage,
    }]

  }])
  return (
    <>
      {/* <Innerheader/> */}
      <div className={`${cascading.innerpages}`}>

        <Grid container >
          <Grid item xl={2.5} lg={2.5} md={12} sm={12} xs={12}>
            <Sidebar />
          </Grid>
          <Grid item xl={9.5} lg={9.5} md={12} sm={12} xs={12} >
            <div className={`${cascading.contentside}`}>
              <div className={`${cascading.communitypage}`}>
                <div className={`row`}>
                  <div className={`col-lg-9 pe-0`}>
                    <div className={`${cascading.postbtnsec}`}>
                      <button onClick={() => setPostsec(!postsec)} className={`${cascading.postbtn} btn`}>Post</button>
                    </div>
                    {postsec &&
                      <Createpost />
                    }

                    <Postsection datas={postdata} />

                  </div>
                  <div className={`col-lg-3 px-0`}>
                    <Userlist />

                  </div>

                </div>

              </div>
              <Innerfooter />
            </div>

          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Community