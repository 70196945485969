import React, { useRef, useState } from 'react'
import cascading from '../assests/scss/login.module.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assests/images/logo.png';
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
export default function Login() {

  const history = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState("password");
  const inputRef = useRef(null);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
      inputRef.current.click();
    }
  };
  const handleLogin = () => {
    
    if (username === 'admin@gmail.com' && password === 'Test@123') {

      history('/console');

      toast.success("Login Succesfully");
    }
    else {
      alert('Invalid credentials. Please try again.');
    }
  };
  const togglePassword = () => {
    // 
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  return (

    <div className={`container `}>
      <div className={`${cascading.login}`}>
        <div className={`row ${cascading.loginrow} mx-3`}>

          <div className={`col-md-4 ${cascading.logincard}`}>
            <div className={`${cascading.logosec}`}>
              <img src={logo} className={`img-fluid`} alt='logo' />
            </div>

            <div className={`${cascading.inputrow} mb-4`}>
              <p className={`${cascading.inputlabel}`}>Email</p>
              <div className={`${cascading.ordinp}`}>
                <input type="email" className={`form-control`} onKeyDown={handleKeyPress}   placeholder="Email" value={username}
                  onChange={(e) => setUsername(e.target.value)} />

              </div>

            </div>
            <div className={`${cascading.inputrow} mb-4`}>
              <p className={`${cascading.inputlabel}`}>Password</p>
              <div className={`${cascading.inputrow} `}>
                <div className={`input-group ${cascading.pwdinpt}`}>
                  <input type={passwordType} className={`form-control`}  onKeyDown={handleKeyPress}  placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  <span className={`input-group-text`} onClick={() => togglePassword()}>  {passwordType === "password" ? <FaEyeSlash /> : <FaEye />}</span>
                </div>

              </div>
            </div>
            <div className={`${cascading.btnsec}`}>
              <button ref={inputRef} className={`btn ${cascading.loginbtn}`} type='submit' onClick={() => handleLogin()}>Login</button>

            </div>
          </div>
        </div>

      </div>






    </div>
  )
}
